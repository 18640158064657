<template>
  <div class="home-page">
    <topbar1></topbar1>
    <!-- 轮播图 -->
    <el-carousel arrow="nerver" indicator-position="none" height="840px">
      <el-carousel-item v-for="(img, index) in imglist" :key="index">
        <img v-bind:src="img" style="width: 100%;height: 100%;object-fit: cover">
      </el-carousel-item>
      <!-- arrow="always" -->
      <!-- <el-carousel-item>
      <img src="@/assets/banner.png">
      <img src="@/assets/banner.png">
    </el-carousel-item> -->
    </el-carousel>
    <!-- 轮播图 -->
    <!-- <div class="top-bg"><img src="@/assets/banner.png"></div> -->
    <div class="top-cover"></div>
    <div class="center-body">
      <div class="apply_img">
        <img src="@/assets/pic_Suppliers.png" class="suppliers">
        <div class="submit1" @click="sup_apply">
          <span size="large" class="applybtn">立即申请</span>
        </div>
        <img src="@/assets/pic_agent.png" class="agent">
        <div class="submit2" @click="agent_apply()">
          <span size="large" class="applybtn">立即加入</span>
        </div>
        <!-- <span class="eheader">Product service and features</span>
        <h1 class="cheader">产品服务及特点</h1>
        <span class="introduce">全品类虚拟商品供货<i></i> 代理一件铺货<i></i> 供应商上全品类</span>
        <div class="icon1"><img src="@/assets/icon_cost.png" class="icon-img">
          <p class="icon-text">话费充值</p>
        </div>
        <div class="pic1"><img src="@/assets/pic_cost.png" class="pic-img">
          <p class="pic-text">话费充值</p>
          <div class="adv-text">
            <span><i></i>移动<i></i>联通<i></i>电信</span>
            <p class="adv-fir">话费充值&nbsp;<i></i>&nbsp;秒到账</p>
            <p>优质供应商渠道&nbsp;<i></i>&nbsp;放心选购</p>
            <p>对供应商的需求&nbsp;<i></i>&nbsp;需要在明确</p>
          </div>
        </div>
        <div class="icon2"><img src="@/assets/icon_video.png" class="icon-img">
          <p class="icon-text">娱乐充值</p>
        </div>
        <div class="pic2"><img src="@/assets/pic_video.png" class="pic-img">
          <p class="pic-text">娱乐充值</p>
          <div class="adv-text">
            <span><i></i>影视会员<i></i>音乐会员</span>
            <p class="adv-fir">爱奇艺、优酷、腾讯、芒果TV</p>
            <p>会员品类多又全</p>
            <p>音乐会员&nbsp;<i></i>&nbsp;QQ音乐、酷狗音乐</p>
          </div>
        </div>
        <div class="icon3"><img src="@/assets/icon_Game.png" class="icon-img">
          <p class="icon-text">游戏充值</p>
        </div>
        <div class="pic3"><img src="@/assets/pic_Game.png" class="pic-img">
          <p class="pic-text">游戏充值</p>
          <div class="adv-text">
            <span><i></i>游戏充值<i></i>点券充值<i></i>QQ币充值</span>
            <p class="adv-fir">各种充值类型多而全</p>
            <p>种类多且丰富</p>
            <p>满足代理商的产品服务各种需求</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import topbar1 from '@/components/topbar1'
export default {
  name: "Homepage",
  // inject:['app'],
  components: {
    topbar1
  },
  data() {
    return {
      user: "",
      state: '',
      checkinfo: '',
      imglist: [
        { url: require('@/assets/banner.png') },
        { url: require('@/assets/banner.png') },
        { url: require('@/assets/banner.png') },
        { url: require('@/assets/banner.png') },
      ],
      activeIndex: '1',
    };
  },
  beforeCreate() {
    this.islogin = this.$store.state.login.islogin;
    this.user = this.$store.state.user.user;
  },
  beforeMount() {
    //  this.isrefresh();

  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    this.carou();
  },
  activated() {
    sessionStorage.setItem("store", JSON.stringify(this.$store.state))
  },
  methods: {
    carou() {
      var that = this;
      $.ajax({
        async: false,
        type: 'post',
        url: this.$store.state.user.z_url + '/index.php?s=/api/Rotation/RotationImg',
        success: function (res) {
          res = JSON.parse(res);
          that.imglist = res.data;
        },
      })
    },
    isrefresh() {
      if (this.$store.state.user.refresh) {
        window.location.reload();
      }
    },
    login_on() {
      this.$router.push('./Login');
    },
    quit() {
      this.$router.push('./Login');
    },
    sup_apply() {
      if (this.$store.state.login.islogin) {
        let u_type = this.$store.state.user.user_type;
        this.getstaus();
        // switch(parseInt(u_type)){
        //   case 0 :
        //     case 4 :
        //       case 1 :
        //         this.getstaus();
        //         if(this.state==-1){
        //           this.$router.push('./suppliers_apply');
        //         }else{
        //           if(this.state=='0'){
        //           this.$router.push('./supplier_checking');
        //         }else if(this.state==1){
        //           this.$router.push('./suppliers_apply');
        //         }if(this.state==2){
        //           this.$router.push('./suppliers_apply');
        //         }
        //         } 
        //     break
        //     case 2 :
        //       case 3 :
        //       case 5 :
        //       this.$router.push('./suppliers_apply')
        // break;
        // }
        if (this.state == -1) {
          this.$router.push('./suppliers_apply');
        } else {
          if (this.state == 0) {
            this.$router.push('./supplier_checking');
          } else if (this.state == 1) {
            this.$router.push('./suppliers_apply');
          } if (this.state == 2) {
            this.$router.push('./suppliers_apply');
          }
        }
      } else {
        this.$message({
          message: '请先登陆',
          type: 'warning',
          center: 'true',
          duration: '1400',
        });
      }
    },
    getstaus() {
      var that = this;
      let params = { 'user_id': this.$store.state.user.vir_id, 'p_id': this.$store.state.user.p_id };
      $.ajax({
        async: false,
        type: 'post',
        url: this.$store.state.user.q_url + '/api/api/supplier_apply_status',
        data: params,
        dataType: 'json',
        success: function (res) {
          if (res.data == 400) {
            that.state = -1
          } else {
            that.checkinfo = res.data[0]
            that.state = that.checkinfo.status;
          }
        }
      })
    },
    agent_apply() {

      if (this.$store.state.login.islogin) {
        let u_type = this.$store.state.user.user_type;
        switch (parseInt(u_type)) {
          case 0:
          case 2:
            this.$router.push('./agent_apply');
            break
          case 1:
          case 3:
            this.$message({
              message: '您已经是代理商了',
              type: 'error',
              center: 'true',
              duration: '2000',
            });
            break;
          case 4:
          case 5:
            this.$router.push('./agent_failure');
            break;
        }
      } else {
        this.$message({
          message: '请先登陆',
          type: 'warning',
          center: 'true',
          duration: '2000',
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;
  min-width: 1560px;


  //轮播图
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .top-cover {
    position: absolute;
    top: 538px;
    left: 0;
    width: 100%;
    height: 314px;
    background: url(../../assets/bg_white.png) no-repeat;
    background-size: 100% 100%;
    z-index: 5000;
  }

  .center-body {
    width: 100%;
    // height: 1750px;
    height: 900px;
    background: url(../../assets/bg_cm.png) no-repeat;
    position: relative;

    .apply_img {
      position: absolute;
      transform: translateX(-50%);
      width: 1300px;
      left: 52%;
      top: 180px
    }

    // .suppliers{position: absolute;left: 360px;top: 70px;cursor: pointer;}
    .agent {
      position: absolute;
      left: 650px;
      top: 241px;
      cursor: pointer;
    }

    .submit1 {
      position: absolute;
      left: 100px;
      top: 264px;
      cursor: pointer;
      border: none;
    }

    .applybtn {
      width: 180px;
      height: 74px;
      background: rgba(61, 49, 226, 1);
      border-radius: 37px;
      display: block;
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: rgba(251, 252, 252, 1);
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      border: none;
      line-height: 74px;
    }

    .submit2 {
      position: absolute;
      left: 753px;
      top: 502px;
    }

    .eheader {
      display: inline-block;
      width: 306px;
      height: 14px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 20px;
      position: absolute;
      left: 507px;
      right: 507;
      top: 747px;
      margin: auto;
    }

    .cheader {
      font-style: normal;
      width: 260px;
      height: 34px;
      font-size: 35px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
      position: absolute;
      left: 510px;
      top: 772px;
      margin: 0;
    }

    .introduce {
      display: inline-block;
      width: 607px;
      height: 23px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
      position: absolute;
      left: 357px;
      right: 357px;
      top: 850px;

      >i {
        margin: 16px;
      }
    }

    .icon1 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 60px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      opacity: 1;
      transition: all 1s ease-out;
      border-radius: 10px;
      z-index: 6000;
    }

    .icon1:hover {
      opacity: 0;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(180deg);
    }

    .icon1:hover+.pic1 {
      opacity: 1;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(360deg);
    }

    .icon2 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 485px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      z-index: 6000;
      opacity: 1;
      transition: all 1s ease-out;
      border-radius: 10px;
    }

    .icon2:hover {
      opacity: 0;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(180deg);
    }

    .icon2:hover+.pic2 {
      opacity: 1;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(360deg);
    }

    .icon3 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 910px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      opacity: 1;
      transition: all 1s ease-out;
      border-radius: 10px;
      z-index: 6000;
    }

    .icon3:hover {
      opacity: 0;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(180deg);
    }

    .icon3:hover+.pic3 {
      opacity: 1;
      transform-style: preserve-3d;
      transform: scale(1.2, 1.2) rotateY(360deg);
    }

    .icon-img {
      width: 180px;
      height: 180px;
      margin-left: 74px;
      margin-top: 74px;
    }

    .icon-text {
      width: 98px;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 15px;
      display: inline-block;
      margin-left: 125px;
    }

    .pic1 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 60px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      opacity: 0;
      transition: all 1s ease-out;
      transform-style: preserve-3d;
      transform: rotateY(180deg);
      border-radius: 10px;
    }

    .pic2 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 485px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      opacity: 0;
      transition: all 1s ease-out;
      transform-style: preserve-3d;
      transform: rotateY(180deg);
      border-radius: 10px;
    }

    .pic3 {
      width: 350px;
      height: 400px;
      position: absolute;
      left: 910px;
      top: 994px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 20px 0px rgba(153, 153, 153, 0.3);
      opacity: 0;
      transition: all 1s ease-out;
      transform-style: preserve-3d;
      transform: rotateY(180deg);
      border-radius: 10px;
    }

    .pic-img {
      width: 130px;
      height: 130px;
      margin-top: 10px;
    }

    .pic-text {
      width: 110px;
      height: 23px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(61, 49, 226, 1);
      line-height: 15px;
      display: block;
      margin: 0 auto;
    }

    .adv-text {
      width: 230px;
      height: 200px;
      margin: 0 auto;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 25px;
      padding-top: 27px;

      >span {
        text-align: center;
        display: block;

        >i {
          margin-left: 8px;
        }
      }

      >p {
        margin: 0;
        text-align: center;
      }

      .adv-fir {
        padding-top: 18px;
      }
    }
  }
}</style>