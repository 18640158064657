<template>
  <div>
    <Homepage />
  </div>
</template>

<script>
// @ is an alias to /src
import Homepage from "@/components/Homepage";

export default {
  name: "index",
  components: {
    Homepage
  }
};
</script>